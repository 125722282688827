import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

const ProgresBar = ({
  sessionDuration,
  currentTime,
  listingMessage,
  onClickSeeker,
}) => {
  const seconds = parseInt(currentTime / 1000);
  useEffect(() => {
    console.log(seconds)
    console.log(sessionDuration)
    console.log(currentTime)
  })
  // console.log("ProgresBarProgresBar ", seconds, sessionDuration, (seconds / sessionDuration) * 100, listingMessage)
  // const onClickSeeker = (t) => {
  //    t
  // }
  return (
    <>
      {listingMessage.length > 0 && <div
        style={{ zIndex: "1000" }}
        id="toolTip"
        className="flex justify-between items-center w-[35rem] h-[0.1rem] bg-BgPrimary mb-[1rem] content-none"
      >
        <div
          style={{
            left: `${(seconds / sessionDuration) * 100}px`,
            zIndex: "1000",
          }}
          className="relative flex justify-center items-center  w-[0.8rem] h-[0.8rem]  bg-[var(--primary-color)]   rounded-full content-none"
        >
          <div className="rounded-full content-none bg-BgPrimary w-[0.3rem] h-[0.3rem]"></div>
        </div>
        {listingMessage?.map((v, i) => {
          return (
            <Tooltip
              title={`${v["message"]}  ${v["lapsedTime"]} ${v?.date}`}
              arrow
            >
              <div
                style={{
                  left: `${(v["lapsedTime"] / sessionDuration) * 100}%`,
                }}
                className={` w-[0.3rem] h-[0.3rem] bg-black rounded-full content-none`}
                onClick={() =>
                  onClickSeeker(v["lapsedTime"], v, i, listingMessage)
                }
              ></div>
              {/* <div style={{background:{color},left: `${(v["lapsedTime"] / sessionDuration) * 100}%`,
                                width:`${(v["lapsedTime"] / sessionDuration) * 100}rem`
                                }} className={`absolute top-[-0.1rem] border-blue-500 border-r-2 bg-red-300 h-[0.2rem] content-none`}></div>   */}
            </Tooltip>
          );
        })}
      </div>}
      {/* ${(v["lapsedTime"] / sessionDuration) * 40} */}
    </>
  );
};

export default ProgresBar;
