import React from "react";

const PlayButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      viewBox="0 -960 960 960"
      width="40"
      fill="var(--svgIconColor)"
    >
      <path d="M405.846-352.615 607.385-480 405.846-607.385v254.77Zm74.613 210.461q-69.673 0-131.343-26.386-61.67-26.386-108.008-72.69-46.338-46.305-72.646-107.746-26.308-61.441-26.308-131.299 0-69.939 26.386-131.109 26.386-61.17 72.69-107.508 46.305-46.338 107.746-72.646 61.441-26.308 131.299-26.308 69.939 0 131.109 26.386 61.17 26.386 107.508 72.69 46.338 46.305 72.646 107.512t26.308 130.799q0 69.673-26.386 131.343-26.386 61.67-72.69 108.008-46.305 46.338-107.512 72.646t-130.799 26.308Zm-.484-28.923q128.294 0 218.621-90.301 90.327-90.302 90.327-218.597 0-128.294-90.301-218.621-90.302-90.327-218.597-90.327-128.294 0-218.621 90.301-90.327 90.302-90.327 218.597 0 128.294 90.301 218.621 90.302 90.327 218.597 90.327ZM480-480Z" />
    </svg>
  );
};

export default PlayButton;
